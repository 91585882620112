import * as React from "react"
import { Link } from "gatsby"

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const BlogList = (props) => {
    console.log(props.blogs);

    const blogList = []
    for (const [key, blog] of props.blogs.entries()) {
        blogList.push(<Card key={key} sx={{ maxWidth: 345 }}>
            <CardMedia
              component="img"
              height="140"
              image={blog.PreviewImage}
              alt="green iguana"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div">
                {blog.Name}
              </Typography>
              <Typography variant="body2" color="text.secondary">
                {blog.Description}
              </Typography>
            </CardContent>
            <CardActions>
              <Button size="small"><Link to={'/blog'} state={{blog: blog}}>Open</Link></Button>
              <Chip label={blog.Version} variant="outlined" style={{ marginLeft: "auto" }} />
            </CardActions>
          </Card>)
    }

    return (
        <React.Fragment>
            {blogList}
        </React.Fragment>
    );
};
