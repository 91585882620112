import React, { useEffect, useState } from "react";
import { Link, graphql } from "gatsby"

import { useMsal, useAccount, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { InteractionStatus, InteractionRequiredAuthError } from "@azure/msal-browser";
import { apiRequest } from "../authConfig";

import Layout from "../components/layout"
import Seo from "../components/seo"
import BlogPreview from "../components/blogPreview"
import { BlogList } from "../components/blogList";
import { getBlogToken } from "../utils/customApiCall"

import { Fab } from "@mui/material"
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack';
import Switch from '@mui/material/Switch';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle'

import { BlobServiceClient, AnonymousCredential } from '@azure/storage-blob';
import { TableClient, AzureSASCredential } from '@azure/data-tables';

const storageAccountName = process.env.staticStorageAccount;
const anonymousCredential = new AnonymousCredential();

let blobServiceClient;
let tableClient;

const BlogsContent = (props) => {
  const posts = props.posts;
  const { instance, accounts, inProgress } = useMsal();
  const account = useAccount(accounts[0] || {});
  const [graphData, setGraphData] = useState(null);
  const [draftMode, switchPreviewDraftMode] = useState(true);

  const authorizedRequest = {
    ...apiRequest,
    account: account
  };

  instance.acquireTokenSilent(authorizedRequest).catch((e) => {
    if (e instanceof InteractionRequiredAuthError) {
        instance.acquireTokenRedirect(authorizedRequest);
    }
  });

  async function getBlogBlobs (sasToken) {
    blobServiceClient = new BlobServiceClient(`https://${storageAccountName}.blob.core.windows.net${sasToken}`, anonymousCredential);
    
    const containerClient = blobServiceClient.getContainerClient("blogs");
    let blobs = containerClient.listBlobsFlat();
  
    let blobList = [];
    let i = 1;
    for await (const blob of blobs) {
        if (blob.name.endsWith(".blog")) {
            blobList.push(blob.name);
        }
        console.log(`Blob ${i++}: ${blob.name}`);
    }
  
    return blobList;
  }

  async function getBlogBlobs2 (sasToken, partitionKey = "Development") {
    var cred = new AzureSASCredential(sasToken);
    tableClient = new TableClient(`https://${storageAccountName}.table.core.windows.net`, "Blogs", cred);

    let entitiesIter = tableClient.listEntities({
      queryOptions: { filter: `PartitionKey eq '${partitionKey}'` },
    });

    let blobList = [];
    let i = 1;
    for await (const entity of entitiesIter) {
      console.log(`Entity${i}: PartitionKey: ${entity.partitionKey} RowKey: ${entity.rowKey}`);
      blobList.push(entity);
      i++;
    }
  
    return blobList;
  }

  function switchDraftPreviewToggle () {
    let newMode = draftMode ? false : true;


    instance.acquireTokenSilent(authorizedRequest).then((response) => {
      getBlogToken(response.accessToken, "Table").then((sasToken) => {
        getBlogBlobs2(sasToken, (draftMode ? "Published" : "Development")).then(value => {
          setGraphData(value)
        })
      });
    })

    switchPreviewDraftMode(newMode)
  }

  useEffect(() => {
    if (account && !graphData && inProgress === InteractionStatus.None) {
        instance.acquireTokenSilent(authorizedRequest).then((response) => {
          getBlogToken(response.accessToken, "Table").then((sasToken) => {
            getBlogBlobs2(sasToken).then(value => {
              setGraphData(value)
            })
          });
        }).catch((e) => {
            if (e instanceof InteractionRequiredAuthError) {
                instance.acquireTokenRedirect(authorizedRequest);
            }
        });
    }
  }, [account, inProgress, instance, graphData]);

  return (
    <div>
      <Typography variant="h2" component="h2" gutterBottom itemProp="headline">
        Blogs
      </Typography>
      <AuthenticatedTemplate>
        <Stack direction="row" spacing={1} alignItems="center">
          <Typography>Drafts</Typography>
          <Switch inputProps={{ 'aria-label': 'Draft or Publish toggle button' }} onClick={switchDraftPreviewToggle} />
          <Typography>Published</Typography>
        </Stack>
        <Link to="/blog" style={{ float: "right" }}>
            <Fab color="secondary" aria-label="new" id="newButton">
                <AddCircleIcon />
            </Fab>
        </Link>
        { graphData ? <BlogList blogs={graphData} /> : null }
      </AuthenticatedTemplate>
      <UnauthenticatedTemplate>
        <Box sx={{ display: 'grid', gap: 4, gridTemplateColumns: { xs: 'repeat(1, 1fr)', md: 'repeat(2, 1fr)', lg: 'repeat(3, 1fr)'} }}>
          {posts.map(post => {
              return (
                <BlogPreview key={post.fields.slug} post={post} />
              )
          })}
        </Box>
      </UnauthenticatedTemplate>
    </div>
  );
};

const BlogsPage = ({ data }) => (
  <Layout>
    <Seo title="Blogs" />
    <Container maxWidth="xl">
      <Box sx={{ my: 4 }}>
        <BlogsContent posts={data.allMarkdownRemark.nodes} />
      </Box>
    </Container>
  </Layout>
)

export default BlogsPage

export const pageQuery = graphql`
  query {
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }
      filter: {frontmatter: {draft: {ne: true}}}) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          previewImage
        }
      }
    }
  }
`
