import * as React from "react"
import { Link } from "gatsby"

import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

/**
 * Renders information about the user obtained from MS Graph
 * @param props 
 */
export const BlogPreview = (props) => {
    console.log(props.post);
    const post = props.post;
    const title = post.frontmatter.title || post.fields.slug

    return (
        <Card sx={{ maxWidth: 345, marginBottom: "25px", height: "fit-content" }} >
            <Link to={post.fields.slug} style={{ textDecoration: "none", color: "inherit" }}>
                <CardActionArea>
                    <CardMedia
                        component="img"
                        height="140"
                        image={post.frontmatter.previewImage ? post.frontmatter.previewImage : "https://cdn.sanity.io/images/5ho08nfv/production/26310230bf276b6456ba36e2e232a9c7ae154b8e-1350x900.png?rect=60,470,610,343&w=1200&h=675&fit=crop&auto=format"}
                        alt="green iguana"
                    />
                    <CardContent>
                        <Typography gutterBottom variant="h5" component="div">
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary" dangerouslySetInnerHTML={{
                            __html: post.frontmatter.description || post.excerpt,
                        }}></Typography>
                    </CardContent>
                    <CardActions>
                        <Chip label={post.frontmatter.date} variant="outlined" style={{ marginLeft: "auto", cursor: "inherit" }} />
                    </CardActions>
                </CardActionArea>
            </Link>
        </Card>
    );
};

export default BlogPreview
